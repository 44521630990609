@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none !important;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
  scroll-padding-top: 150px;
}

.nav-button {
  box-shadow: 0px 0px 0px 2px #ffffff inset;
}

/* Hero */
.hero-title-wrapper h1 {
  @apply flex flex-col;
}

.hero-title-wrapper .title-upper {
  @apply text-lg uppercase;
}

.hero-title-wrapper .title-main {
  @apply font-serif text-5xl font-normal uppercase md:text-7xl;
}

.hero-title-wrapper .title-main i {
  @apply font-accent text-6xl font-bold normal-case text-secondary md:text-8xl;
  line-height: 70%;
}

.hero-title-wrapper .title-second-line {
  @apply font-serif text-4xl font-normal uppercase md:text-6xl;
  line-height: 70%;
}

.text-center-left {
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}
